import { useTranslation } from "react-i18next";
import Input from "../../components/Input/Input";
import { useForm } from "react-hook-form";
import Textarea from "../../components/Textarea/Textarea";
import Button from "../../components/Button/Button";
import emailjs from "@emailjs/browser";
import { useState } from "react";
import ToastContainer from "../../components/ToastContainer/ToastContainer";

export default function ContactForm() {
  const { t } = useTranslation();
  const [toastMessageText, setToastMessageText] = useState("");
  const toastMessageShowDuration = 2500;
  const formFields = t("pages.contactPage.form.formFields", {
    returnObjects: true,
  });
  const {
    register,
    handleSubmit,
    getValues,
    formState: { isValid, errors },
  } = useForm<any>();

  function onSubmit(event: any) {
    if (isValid) {
      const formData = getValues();
      sendMail(formData);
    }
  }

  function sendMail(formData) {
    const publicKey: string = process.env.REACT_APP_EMAI_JS_PK || "";
    const serviceId: string = process.env.REACT_APP_EMAIL_SERVICE_ID || "";
    const templateId: string = process.env.REACT_APP_EMAIL_TEMPLATE_ID || "";

    emailjs
      .send(serviceId, templateId, formData, publicKey)
      .then((res) => {
        showToastMessage(t("pages.contactPage.formSuccessMessage"));
        console.log("Mesajınız alınmıştır,", res);
      })
      .catch((error) => {
        showToastMessage(t("pages.contactPage.formErrorMessage"));
        console.log("Mesaj Gönderilemedi,", error);
      });
  }

  function showToastMessage(
    message: string,
    duration: number = toastMessageShowDuration
  ) {
    setToastMessageText(message);
    setTimeout(() => {
      hideToastMessage();
    }, duration);
  }

  function hideToastMessage() {
    setToastMessageText("");
  }
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="lg:grid grid-cols-2 gap-4">
          {formFields
            .filter((formField) => formField.elementType !== "textarea")
            .map((formField) => {
              return (
                <div key={formField.id}>
                  <Input
                    key={formField.id}
                    formField={formField}
                    register={register}
                    errors={errors}
                  />
                </div>
              );
            })}
        </div>
        {formFields
          .filter((formField) => formField.elementType === "textarea")
          .map((formField) => {
            return (
              <div key={formField.id}>
                <Textarea
                  key={formField.id}
                  formField={formField}
                  register={register}
                  errors={errors}
                />
              </div>
            );
          })}

        <Button type="submit" classNames="py-4 px-12 mt-4">
          Send Message
        </Button>
      </form>
      {!!toastMessageText && (
        <ToastContainer>
          <p className="text-3xl font-bold text-center drop-shadow-md max-w-lg">
            {toastMessageText}
          </p>
        </ToastContainer>
      )}
    </>
  );
}
