import Paragraph from "../../components/Typography/Paragraph/Paragraph";
import { useTranslation } from "react-i18next";
import Title from "../../components/Typography/Title/Title";
import Article from "../../components/Typography/Article/Article";
import Container from "../../components/Layout/Container/Container";

export default function Information() {
  const { t } = useTranslation();

  const questions = t("pages.informationPage.questions", {
    returnObjects: true,
  });
  return (
    <Container classNames="py-24 md:py-36 lg:py-44">
      <Title level={2} innerHtml={t("pages.informationPage.pageTitle")} />
      <Title
        level={4}
        innerHtml={t("pages.informationPage.pageText")}
        classNames="mb-14 mt-4"
      />

      {questions.map((question) => {
        return (
          <Article key={question.id} classNames="mb-8">
            <Title level={3} innerHtml={question.question} />
            <Paragraph innerHtml={question.answer} />
          </Article>
        );
      })}
    </Container>
  );
}
