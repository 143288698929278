import './paragraph.css';

interface ParagraphProps {
  innerHtml: string | TrustedHTML;
  classNames?: string;
}

export default function Paragraph({ innerHtml, classNames }: ParagraphProps) {
  const sanitizedHtml = innerHtml ?? "";

  return (
    <p
      className={`text-dark font-base ${classNames}`}
      dangerouslySetInnerHTML={{ __html: sanitizedHtml }}
    ></p>
  );
}
