import { TransitionGroup, CSSTransition } from "react-transition-group";
import { useLocation } from "react-router-dom";

export default function AnimatedRoute({ children }) {
  const location = useLocation();
  return (
    <TransitionGroup>
      <CSSTransition
        key={location.key}
        in={true}
        classNames="fade"
        timeout={500}
      >
        {children}
      </CSSTransition>
    </TransitionGroup>
  );
}
