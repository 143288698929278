export default function Textarea({ formField, register, errors }) {
  return (
    <div className="input-wrapper flex flex-col gap-4 mb-6">
      <label
        htmlFor={formField.label}
        className="text-neutral-700 text-base font-normal"
      >
        {formField.label}
      </label>
      <textarea
        type="text"
        className="px-8 py-6 opacity-40 bg-orange rounded-sm placeholder:text-dark"
        placeholder={formField.placeholder}
        {...register(formField.name, {
          ...formField.validations,
        })}
      />
      {errors[formField.name] && (
        <p className="text-white text-base font-normal">
          {errors[formField.name].message}
        </p>
      )}
    </div>
  );
}
