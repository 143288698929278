import React from "react";
interface PropTypes {
  classNames?: string;
  children?: React.ReactNode;
}

export default function GridContainer({ classNames, children }: PropTypes) {
  return (
    <div
      className={`grid gap-4 md:gap-8 lg:gap-12 px-4 md:px-8 lg:px-0 ${
        classNames || ""
      }`}
    >
      {children}
    </div>
  );
}
