import { useState } from "react";
import { useTranslation } from "react-i18next";
import Menu from "../Menu/Menu";
import Container from "../Layout/Container/Container";

import { CSSTransition, TransitionGroup } from "react-transition-group";

export default function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { i18n } = useTranslation();

  function handleLangSelect(event: any) {
    const language = event.target.value;
    i18n.changeLanguage(language);
  }

  function menuIconClickHandler() {
    setIsMenuOpen(!isMenuOpen);
  }
  return (
    <>
      <Container classNames="fixed top-0 left-0 right-0 py-4 px-4 md:px-8 lg:px-0 w-full z-30">
        <nav className="flex justify-between items-center">
          <div
            onClick={menuIconClickHandler}
            className="menu-icon cursor-pointer"
          >
            <Menu.CloseButton
              label="kapat"
              isMenuOpen={isMenuOpen}
            ></Menu.CloseButton>
          </div>

          <div className="lang-select cursor-pointer">
            <select
              className="bg-transparent focus:outline-none"
              onChange={handleLangSelect}
            >
              <option value="tr">Türkçe</option>
              <option value="en">English</option>
            </select>
          </div>
        </nav>
      </Container>

      <TransitionGroup>
        {isMenuOpen && (
          <CSSTransition classNames="menu-animation" timeout={300}>
            <Menu setIsMenuOpen={setIsMenuOpen} />
          </CSSTransition>
        )}
      </TransitionGroup>
    </>
  );
}
