import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./locales/en/index.json";
import tr from "./locales/tr/index.json";

const resources = {
  en: {
    translation: en,
  },
  tr: {
    translation: tr,
  },
};

i18n
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources: resources,
    lng: "tr-TR",
    debug: true,
  });

export default i18n;
