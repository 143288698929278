import Profile from "../../components/Profile/Profile";
import { useTranslation } from "react-i18next";
import TruncatedText from "../../components/TruncatedText/TruncatedText";
import { NavLink } from "react-router-dom";
import GridContainer from "../../components/Layout/GridContainer/GridContainer";
import Column from "../../components/Layout/Column/Column";
import Container from "../../components/Layout/Container/Container";
import Paragraph from "../../components/Typography/Paragraph/Paragraph";
import Button from "../../components/Button/Button";

export default function Home() {
  const { t } = useTranslation();
  const introText = t("pages.homePage.introText", {
    returnObjects: true,
  });

  return (
    <div className="h-full md:h-screen bg-[url('/src/assets/img/plants-bg.png')] bg-no-repeat bg-contain bg-top-center lg:bg-right py-24 md:py-36 lg:py-44">
      <Container classNames="h-auto lg:h-full">
        <GridContainer classNames="grid-cols-1 lg:grid-cols-2 h-auto lg:h-full">
          <Column classNames="order-2 lg:order-1">
            <div className="h-full flex flex-col justify-center">
              <Profile
                name={t("profile.name")}
                jobTitle={t("profile.title")}
                classNames="mb-12 text-center lg:text-left"
              />

              <TruncatedText
                text={introText}
                characterCount={130}
                classNames="lg:text-2xl mb-12 text-center lg:text-left text-neutral-700 lg:leading-tight"
              />

              <NavLink to="/about" className="mx-auto lg:m-0 ">
                <Button>{t("pages.homePage.mainButtonText")}</Button>
              </NavLink>
            </div>
          </Column>
          <Column classNames="order-1 lg:order-2">
            <div className="flex justify-center mb-40 mt-32">
              <NavLink
                to="/contact"
                className="w-48 h-48 lg:w-80 lg:h-80 shadow-lg bg-white bg-opacity-90 hover:bg-white rounded-full self-end -mb-5 lg:-mb-20 -mr-16 lg:-mr-32 z-10 flex items-center justify-center  transition-all duration-200"
              >
                <div className="text-center">
                  <Paragraph
                    classNames="text-orange text-xs lg:text-lg font-bold"
                    innerHtml={t("pages.homePage.smallCircle.label")}
                  />
                  <Paragraph
                    classNames="opacity-70 text-dark text-xs font-normal"
                    innerHtml={t("pages.homePage.smallCircle.subText")}
                  />
                </div>
              </NavLink>
              <NavLink
                to="/info"
                className=" w-52 h-52 shadow-lg lg:w-96 lg:h-96 bg-orange bg-opacity-90 hover:bg-orange hover:z-20 rounded-full flex items-center justify-center"
              >
                <div className="w-52 text-center">
                  <Paragraph
                    classNames=" text-stone-200 text-sm lg:text-2xl font-black"
                    innerHtml={t("pages.homePage.bigCircle.label")}
                  />
                  <Paragraph
                    classNames="opacity-70 text-dark text-xs font-normal"
                    innerHtml={t("pages.homePage.bigCircle.subText")}
                  />
                </div>
              </NavLink>
            </div>
          </Column>
        </GridContainer>
      </Container>
    </div>
  );
}
