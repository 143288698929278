import Title from "../Typography/Title/Title";

interface PropTypes {
  name: string;
  jobTitle: string;
  classNames?: string;
  nameColor?: string;
  jobTitleColor?: string;
}
export default function Profile({
  name,
  jobTitle,
  classNames,
  nameColor,
  jobTitleColor,
}: PropTypes) {
  return (
    <div className={`flex flex-col ${classNames}`}>
      <Title level={5} innerHtml={jobTitle} classNames={jobTitleColor} />
      <Title level={2} innerHtml={name} colorClass={nameColor} />
    </div>
  );
}
