import Badge from "../../components/Badge/Badge";
import Paragraph from "../../components/Typography/Paragraph/Paragraph";
import Profile from "../../components/Profile/Profile";
import { useTranslation } from "react-i18next";
import "./about.css";
import profileImg from "./../../assets/img/yasin_arslan.jpg";
import GridContainer from "../../components/Layout/GridContainer/GridContainer";
import Column from "../../components/Layout/Column/Column";
import Container from "../../components/Layout/Container/Container";
import Title from "../../components/Typography/Title/Title";

export default function About() {
  const { t } = useTranslation();
  const paragraphs = t("pages.aboutPage.paragraphs", { returnObjects: true });
  const resources = t("pages.aboutPage.externalResources.resources", {
    returnObjects: true,
  });

  const tags = t("pages.aboutPage.tags", { returnObjects: true }) || [];

  return (
    <Container classNames="py-24 md:py-36 lg:py-44">
      <GridContainer classNames="grid-cols-12 lg:gap-16">
        <Column classNames="col-span-4 hidden lg:block">
          <img
            className="profile-img shadow-lg"
            alt="Yasinarslan profile img"
            src={profileImg}
          />
          <Title
            level={2}
            classNames="mt-16 mb-6"
            innerHtml={t("pages.aboutPage.externalResources.title")}
          ></Title>
          <Title
            level={4}
            classNames="mb-3"
            innerHtml={t("pages.aboutPage.externalResources.subText")}
          ></Title>

          <ol style={{ listStyleType: "decimal" }}>
            {resources.map((resource) => {
              return (
                <li key={resource.id} className="mb-2  text-justify">
                  <Paragraph innerHtml={resource.text}></Paragraph>
                  {resource.link !== "#" && (
                    <a
                      href={resource.link}
                      className="text-sm text-dark underline hover:text-black"
                    >
                      {resource.link}
                    </a>
                  )}
                </li>
              );
            })}
          </ol>
        </Column>
        <Column classNames="col-span-12 lg:col-span-8">
          <Profile
            name={t("profile.name")}
            jobTitle={t("profile.title")}
            classNames="hidden lg:block mb-28"
          />

          {paragraphs.map((paragraph) => {
            if (paragraph.id === 1) {
              return (
                <div key={paragraph.id}>
                  <Paragraph
                    classNames="lg:text-5xl mb-12 text-center lg:text-left text-neutral-700 lg:leading-tight"
                    innerHtml={paragraph.text}
                  />

                  <div className="flex flex-wrap gap-6 mt-12 mb-12 justify-center lg:justify-normal">
                    {tags.map((tag) => (
                      <Badge key={tag.id} label={tag.label} />
                    ))}
                  </div>
                </div>
              );
            } else {
              return (
                <Paragraph
                  key={paragraph.id}
                  classNames="lg:text-5xl mb-12 text-center lg:text-left text-neutral-700 lg:leading-tight"
                  innerHtml={paragraph.text}
                />
              );
            }
          })}
        </Column>
      </GridContainer>
    </Container>
  );
}
