import GridContainer from "../../components/Layout/GridContainer/GridContainer";
import Column from "../../components/Layout/Column/Column";
import Container from "../../components/Layout/Container/Container";
import Title from "../../components/Typography/Title/Title";
import ContactForm from "../../components/ContactForm/ContactForm";
import { useTranslation } from "react-i18next";

export default function Contact() {
  const { t } = useTranslation();
  return (
    <Container classNames="py-24 md:py-36 lg:py-44">
      <GridContainer classNames="grid-cols-1 lg:grid-cols-6 mx-auto">
        <Column classNames="col-span-full lg:col-start-2 lg:col-span-4">
          <Title level={3} innerHtml={t("pages.contactPage.pageSubtitle")} />

          <Title
            level={5}
            innerHtml={t("pages.contactPage.form.formNotificationText")}
            classNames="mb-14 mt-4 font-normal"
          />
          <ContactForm></ContactForm>
        </Column>
      </GridContainer>
    </Container>
  );
}
