import React from "react";

interface PropTypes {
  classNames?: string;
  children: React.ReactNode;
}

export default function Container({ classNames, children }: PropTypes) {
  return <div className={`container mx-auto ${classNames}`}>{children}</div>;
}
