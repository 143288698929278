import "./footer.css";
import { useTranslation } from "react-i18next";

export default function Footer() {
  const { t } = useTranslation();
  const socials = t("profile.socialLinks", { returnObjects: true });
  return (
    <div className="flex flex-col md:flex-row items-center md:justify-between py-5">
      <span className="order-2 md:order-1">
        © {t("components.footer.copyrightText")}
      </span>
      <ul className="socials flex order-1 md:order-2">
        {t("components.footer.profilesText")} | 
        {socials.map((socialLink) => {
          return (
            <li key={socialLink.id}>
              <a href={socialLink.slug} className="font-bold">
                {socialLink.label}
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
