import React from "react";

interface PropTypes {
  classNames?: string;
  children: React.ReactNode;
}

export default function ToastContainer({
  classNames = "",
  children,
}: PropTypes) {
  const defaultClassNames =
    "fixed top-0 left-0 right-0 bottom-0 w-full h-screen bg-orange bg-opacity-70 z-50 flex items-center justify-center";
  return <div className={`${defaultClassNames} ${classNames}`}>{children}</div>;
}
