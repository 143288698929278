import { NavLink } from "react-router-dom";
import "./menu.css";
import Footer from "../Footer/Footer";
import Profile from "../Profile/Profile";
import { useTranslation } from "react-i18next";
import GridContainer from "../Layout/GridContainer/GridContainer";
import Column from "../Layout/Column/Column";
import Container from "../Layout/Container/Container";

function CloseButton({ isMenuOpen, label }) {
  return (
    <>
      <div className="close-button flex items-center gap-2">
        <div
          className={`hamburger-icon ${
            isMenuOpen ? "menu-opened" : "menu-closed"
          }`}
        >
          <div className="line bg-dark"></div>
          <div className="line bg-dark"></div>
          <div className="line bg-dark"></div>
        </div>
        <span className="close-button-label">{label}</span>
      </div>
    </>
  );
}

export default function Menu({ setIsMenuOpen }) {
  const { t } = useTranslation();
  const links = t("components.nav.links", { returnObjects: true });

  function handleNavLinkClick() {
    setIsMenuOpen(false);
  }
  return (
    <div className="fixed h-screen top-0 left-0 right-0 mx-auto z-20 bg-orange pt-16">
      <Container classNames="h-full flex flex-col">
        <GridContainer classNames="grid-cols-1 lg:grid-cols-2 mt-auto mb-auto">
          <Column>
            <Profile
              name={t("profile.name")}
              jobTitle={t("profile.title")}
              classNames="hidden lg:block mb-20"
              nameColor="text-white"
            />

            <ul className="nav-menu-links text-center lg:text-left">
              {links.map((link) => {
                return (
                  <li key={link.id}>
                    <NavLink
                      to={link.slug}
                      className="nav-menu-link text-white text-2xl font-dark leading-10 hover:text-dark lg:font-extrabold lg:text-7xl transition-all duration-200"
                      onClick={handleNavLinkClick}
                    >
                      {link.label}
                    </NavLink>
                  </li>
                );
              })}
            </ul>
          </Column>
          <Column>
            <div className="flex flex-col justify-center items-center mb-6 mt-20 text-center lg:text-right lg:items-end lg:mb-16 lg:mt-0">
              <p className=" text-neutral-700 text-lg font-bold mb-4">
                {t("components.navMenu.adressTitle")}
              </p>
              <p className="w-48  text-neutral-700 text-lg font-normal">
                {t("profile.adress")}
              </p>
            </div>
            {!!t("profile.phone").length && (
              <div className="flex flex-col justify-center items-center mb-6  text-center lg:text-right lg:items-end lg:mb-16">
                <p className=" text-neutral-700 text-lg font-bold mb-4">
                  {t("components.navMenu.phoneTitle")}
                </p>
              </div>
            )}

            {t("profile.mail") && (
              <div className="flex flex-col justify-center items-center mb-6  text-center lg:text-right lg:items-end lg:mb-16">
                <p className=" text-neutral-700 text-lg font-bold mb-4">
                  {t("components.navMenu.mailTitle")}
                </p>
                <p className="w-48  text-neutral-700 text-lg font-normal">
                  {t("profile.mail")}
                </p>
              </div>
            )}
          </Column>
        </GridContainer>
        <Footer></Footer>
      </Container>
    </div>
  );
}

Menu.CloseButton = CloseButton;
