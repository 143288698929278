import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import "./i18n";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import MainLayout from "./layouts/MainLayout";
import Home from "./pages/Home/Home";
import About from "./pages/About/About";
import Contact from "./pages/Contact/Contact";
import Information from "./pages/Information/Information";
import AnimatedRoute from "./components/AnimatedRoute/AnimatedRoute";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const routes = [
  {
    path: "/",
    element: <MainLayout />,
    children: [
      {
        path: "/",
        element: (
          <AnimatedRoute>
            <Home />
          </AnimatedRoute>
        ),
        index: true,
      },
      {
        path: "/about",
        element: (
          <AnimatedRoute>
            <About />
          </AnimatedRoute>
        ),
      },
      {
        path: "/contact",
        element: (
          <AnimatedRoute>
            <Contact />
          </AnimatedRoute>
        ),
      },
      {
        path: "/info",
        element: (
          <AnimatedRoute>
            <Information />
          </AnimatedRoute>
        ),
      },
    ],
  },
];

const router = createBrowserRouter(routes);
root.render(<RouterProvider router={router}></RouterProvider>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
