interface PropsType {
  text: string;
  characterCount: number;
  classNames?: string;
}

export default function TruncatedText(props: PropsType) {
  const trimmedText = props.text.substring(0, props.characterCount);
  return <p className={props.classNames}>{trimmedText}...</p>;
}
