import { CSSTransition, TransitionGroup } from "react-transition-group";

export default function Input({ formField, register, errors }) {
  return (
    <div className="input-wrapper flex flex-col gap-4 mb-6">
      <label
        htmlFor={formField.label}
        className="text-neutral-700 text-base font-normal"
      >
        {formField.label}
      </label>
      <input
        type="text"
        className="px-8 py-6 opacity-40 bg-orange rounded-sm placeholder:text-dark"
        placeholder={formField.placeholder}
        {...register(formField.name, {
          ...formField.validations,
          ...(formField.name === "mail"
            ? {
                pattern: {
                  ...formField.validations.pattern,
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                },
              }
            : null),
        })}
      />

      <TransitionGroup>
        {errors[formField.name] && (
          <CSSTransition classNames="fade" timeout={200}>
            <p className="text-white text-base font-normal">
              {errors[formField.name].message}
            </p>
          </CSSTransition>
        )}
      </TransitionGroup>
    </div>
  );
}
