import React from "react";

interface PropTypes {
  level: 1 | 2 | 3 | 4 | 5 | 6;
  classNames?: string;
  innerHtml?: string;
  colorClass?: string;
}

export default function Title({
  level,
  classNames,
  innerHtml,
  colorClass = "text-dark",
}: PropTypes) {
  const sanitizedHtml = innerHtml ?? "";

  switch (level) {
    case 1:
      return (
        <h1
          className={`text-xl xs:text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold ${colorClass} ${classNames}`}
          dangerouslySetInnerHTML={{ __html: sanitizedHtml }}
        ></h1>
      );
    case 2:
      return (
        <h2
          className={`text-lg xs:text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold ${colorClass} ${classNames}`}
          dangerouslySetInnerHTML={{ __html: sanitizedHtml }}
        ></h2>
      );
    case 3:
      return (
        <h3
          className={`text-base xs:text-lg sm:text-xl md:text-2xl lg:text-3xl font-bold ${colorClass} ${classNames}`}
          dangerouslySetInnerHTML={{ __html: sanitizedHtml }}
        ></h3>
      );
    case 4:
      return (
        <h4
          className={`text-base sm:text-lg md:text-xl lg:text-2xl font-bold ${colorClass} ${classNames}`}
          dangerouslySetInnerHTML={{ __html: sanitizedHtml }}
        ></h4>
      );
    case 5:
      return (
        <h5
          className={`text-xl font-bold text-dark leading-[1.3] ${colorClass} ${classNames}`}
          dangerouslySetInnerHTML={{ __html: sanitizedHtml }}
        ></h5>
      );
    case 6:
      return (
        <h6
          className={`text-lg font-bold text-dark  ${colorClass} ${classNames}`}
          dangerouslySetInnerHTML={{ __html: sanitizedHtml }}
        ></h6>
      );
    default:
      return null;
  }
}
