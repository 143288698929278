export default function Badge(props) {
  return (
    <div
      className="px-8 py-4 bg-neutral-700 rounded-full border border-neutral-700 flex items-center justify-center min-w-max"
      {...props}
    >
      <p className="text-stone-200 text-sm font-bold tex-center">
        {props.label}
      </p>
    </div>
  );
}
