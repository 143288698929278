import React from "react";

interface PropTypes {
  label?: string;
  classNames?: string;
  children: React.ReactNode;
  onClick?: any;
  type?: "button" | "submit" | "reset";
}

export default function Button({
  label,
  classNames,
  children,
  onClick,
  type = "button",
}: PropTypes) {
  const defaultClass =
    "px-8 py-4 shadow-lg text-stone-50 text-lg font-bold rounded-full bg-orange hover:bg-opacity-50 transition-all duration-200";
  return (
    <button
      type={type}
      onClick={onClick}
      className={`${defaultClass} ${classNames}`}
    >
      {children || label}
    </button>
  );
}
