import { Helmet, HelmetProvider } from "react-helmet-async";
import { Outlet, ScrollRestoration } from "react-router-dom";
import Navbar from "../components/Navbar/Navbar";
import Footer from "../components/Footer/Footer";
import Container from "../components/Layout/Container/Container";
import { useEffect, useState } from "react";
import { CSSTransition } from "react-transition-group";

function MainLayout() {
  const [showAnimation, setShowAnimation] = useState(false);
  useEffect(() => {
    setShowAnimation(true);
  }, []);
  return (
    <>
      <HelmetProvider>
        <ScrollRestoration
          getKey={(location, matches) => {
            // default behavior
            return location.key;
          }}
        />
        <Helmet>
          <title>yasinarslan.com</title>
        </Helmet>

        <CSSTransition
          in={showAnimation}
          classNames="fade"
          timeout={1000}
          unmountOnExit
        >
          <div className="gradient-bg select-none">
            <Container>
              <Navbar></Navbar>
              <Outlet></Outlet>
              <Footer></Footer>
            </Container>
          </div>
        </CSSTransition>
      </HelmetProvider>
    </>
  );
}

export default MainLayout;
