import React from "react";

interface PropTypes {
  classNames?: string;
  children?: React.ReactNode;
}

export default function Article({ classNames, children }: PropTypes) {
  return (
    <article className={`flex flex-col gap-2 ${classNames}`}>
      {children}
    </article>
  );
}
